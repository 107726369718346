.list-group-item.active {
    z-index: 0;
    color: #FFFFFF;
    background-color: #4285f4;
    border-color: #4285f4;
  }




  /* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.6s;
  cursor: pointer;
}
.ripple:hover {
  /* background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%; */
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}



 /* Block UI */

 .block-ui{
  position : initial
 }
.block-ui-message {
  color: #333;
  background: none;
  font-size:  32px;
  z-index: 1011;
}

.block-ui-overlay {
  opacity: 0.8;
}

/* span.hover-underline {
  text-decoration: underline;
  font-size : 14px
} */
span.hover-underline:hover {
  text-decoration: underline;
  cursor:  'pointer'
}

.fuelux .wizard > .steps-container > .steps li {
  width: 160px;
}

.actions.move button.wide{
  width: 120px
}


/* numpad */

#keyboard {
  margin: 0;
  padding: 0;
  list-style: none;
}
#keyboard li {
  float: left;
  margin: 0 5px 5px 0;
  width: 60px;
  height: 60px;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  background: #fff;
  border: 1px solid #f9f9f9;
  border-radius: 5px;
}
.capslock,
.tab,
.left-shift,
.clearl,
.switch {
  clear: left;
}
#keyboard .tab,
#keyboard .delete {
  width: 70px;
}
#keyboard .capslock {
  width: 80px;
}
#keyboard .return {
  width: 90px;
}
#keyboard .left-shift {
  width: 70px;
}

#keyboard .switch {
  width: 90px;
}
#keyboard .rightright-shift {
  width: 109px;
}
.lastitem {
  margin-right: 0;
}
.uppercase {
  text-transform: uppercase;
}
#keyboard .space {
  float: left;
  width: 556px;
}
#keyboard .switch,
#keyboard .space,
#keyboard .return {
  font-size: 16px;
}
.on {
  display: none;
}
#keyboard li:hover {
  position: relative;
  top: 1px;
  left: 1px;
  border-color: #e5e5e5;
  cursor: pointer;
}




/* counter */

.counter .desc {
  text-transform: uppercase;
  font-size: .8462rem;
  margin-top: 8px;
  color: #9d9d9d;
}


.form-group.row.pos {
  padding: 4px
}

.form-group.row.pos label {
  padding-top: 8px
}


.user-timeline-date{
  font-size: 1.2rem
}


ul.list-summary {
  list-style-type: none;
}

ul.summary-list li.summary-list-item {
  list-style-type: none;
  font-size: 1.5rem;
  border-bottom: 1px dashed gainsboro;  
  line-height: 30px
}

ul.summary-list li.summary-list-item p{
  line-height: 30px;
  margin : 0.75rem 0.25rem;
  font-weight: 300;
}

ul.summary-list li.summary-list-item .item-left {
   
}
ul.summary-list li.summary-list-item .item-right{
  float: right
}