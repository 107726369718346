
.wrapper {
    display: flex;
    justify-content: space-between;
    width: 460px;
  }
  .dd-wrapper {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    display: inline-block;
    width: 60px;
  }
  .dd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 20px;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    cursor: default;
    position: relative;
    background-color: #fff;
  }
  .dd-header span {
    margin-right: 20px;
  }
  .dd-header-title {
     margin-left: 8px;
    color : gray;
  }
  .angle-down {
    color: #000;
    margin-right: 20px;
  }
  .dd-list {
    z-index: 10;
    position: absolute;
    width: 120px;
    border: 1px solid #dfdfdf;
    /* border-top: none; */
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: #fff;
    box-shadow: 0 2px 5px -1px #e8e8e8;
    font-weight: 700;
    padding: 15px 0;
    max-height: 215px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .dd-list-item {
    width: 100%;
    
    padding: 8px 10px;
     
    cursor: default;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dd-list-item.selected {
    color: #fff;
    background-color: #ffcc01;
  }
  .dd-list-item:hover {
    color: #fff;
    background-color: #ffcc01;
  }
  .dd-wrapper-single {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    width: 265px;
  }
  .dd-wrapper-single .dd-header {
    border: 1px solid #ccc;
  }
  .dd-wrapper-single .dd-header .dd-header-name {
    font-weight: 400;
  }
  .dd-wrapper-single .dd-list {
    border: 1px solid #ccc;
    border-top: none;
  }
.date-row-container {
  display: grid;
  /* grid-template-columns: 150px 8fr; */
  grid-template-columns: 150px  1fr;
  border-bottom-color: #f1f3f4;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: -1px;
   
  min-height: 48px !important;
}

.date {
  grid-column: 1 / 2;
  font-size: 20px;
}

.date-bottom {
  /* border-bottom-color: lightgray;
  border-bottom-style: solid;
  border-bottom-width: 1px; */
  padding-left: 16px;
}

/* 15 hours */
/* fraction by 10 min */
/* get 90 (10 min fraction) */

.grid-container2 {
  display: grid;
  grid-template-columns: repeat(90, 1fr);

  width: 960px;
  margin-bottom: -1px;

  border-bottom-color: #f1f3f4;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: -1px;
}

.time-header {
  display: grid;
  grid-template-columns: repeat(90, 1fr);
  /* grid-template-columns: repeat(90, 35px); */
  width: 960px;
  /* height: 600px; */

  position: absolute;
  left: 165px;
}

.time-header > div {
  /* width : 120px; */
  padding-top: 8px;
  padding-left: 8px;

  /* text-align: center; */
}

/* #d6d7d8   #F1F3F4 */
.time-box-left {
  border-left-color: #d6d7d8;
  border-left-style: solid;
  border-left-width: 1px;
}
.time-box-right {
  border-right-color: #d6d7d8;
  border-right-style: solid;
  border-right-width: 1px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  /* font-size: 15px; */
  text-align: left;
  padding-top: 5px;
  padding-left: 8px;

  margin: 2px;

  color: white;
  /* background-color: #2196f3; */
  /* width : 120px; */
  z-index: 0;
}

.grid-item > div.line1 {
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden !important;
}

.grid-item > div.line2 {
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden !important;
}

.event1 {
  grid-column: 1 / 5;
  grid-row: 1;
}
.event2 {
  grid-column: 7 / 11;
  grid-row: 1;
}

.th-grid-container {
  display: grid;
  grid-template-columns: 150px 8fr;
}

.th-grid-container .data {
  margin-bottom: -1px;
  height: 60px;
   padding : 0px 20px;
}

.th-grid-container-2 {
  display: grid;
  grid-template-columns: repeat(30, 1fr);
}

.th-grid-container-2 > div.slot {
  border: 1px solid gray;
  width: 80px;
  height: 60px;
  margin-bottom: -1px;
  margin-right: -1px;
  text-align: center;

}

.Select-value-label,
.Select-option {
  white-space: pre-wrap;
}

.th-grid-container {
    display: grid;
    grid-template-columns: 150px 8fr;
  }
  
  .th-grid-container .data {
    margin-bottom: -1px;
    height: 60px;
     padding : 0px 20px;
  }
  
  .th-grid-container-2 {
    display: grid;
    grid-template-columns: repeat(30, 1fr);
  }
  
  .th-grid-container-2 > div.slot {
    border: 1px solid gray;
    width: 80px;
    height: 60px;
    margin-bottom: -1px;
    margin-right: -1px;
    text-align: center;
  
  }
  
.loaderModal {
    /* display: none;  */
    /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 15%; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
  }
  ​
  .loaderModalContent {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }
.list-group-item.active {
    z-index: 0;
    color: #FFFFFF;
    background-color: #4285f4;
    border-color: #4285f4;
  }




  /* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.6s;
  cursor: pointer;
}
.ripple:hover {
  /* background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%; */
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}



 /* Block UI */

 .block-ui{
  position : static;
  position : initial
 }
.block-ui-message {
  color: #333;
  background: none;
  font-size:  32px;
  z-index: 1011;
}

.block-ui-overlay {
  opacity: 0.8;
}

/* span.hover-underline {
  text-decoration: underline;
  font-size : 14px
} */
span.hover-underline:hover {
  text-decoration: underline;
  cursor:  'pointer'
}

.fuelux .wizard > .steps-container > .steps li {
  width: 160px;
}

.actions.move button.wide{
  width: 120px
}


/* numpad */

#keyboard {
  margin: 0;
  padding: 0;
  list-style: none;
}
#keyboard li {
  float: left;
  margin: 0 5px 5px 0;
  width: 60px;
  height: 60px;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  background: #fff;
  border: 1px solid #f9f9f9;
  border-radius: 5px;
}
.capslock,
.tab,
.left-shift,
.clearl,
.switch {
  clear: left;
}
#keyboard .tab,
#keyboard .delete {
  width: 70px;
}
#keyboard .capslock {
  width: 80px;
}
#keyboard .return {
  width: 90px;
}
#keyboard .left-shift {
  width: 70px;
}

#keyboard .switch {
  width: 90px;
}
#keyboard .rightright-shift {
  width: 109px;
}
.lastitem {
  margin-right: 0;
}
.uppercase {
  text-transform: uppercase;
}
#keyboard .space {
  float: left;
  width: 556px;
}
#keyboard .switch,
#keyboard .space,
#keyboard .return {
  font-size: 16px;
}
.on {
  display: none;
}
#keyboard li:hover {
  position: relative;
  top: 1px;
  left: 1px;
  border-color: #e5e5e5;
  cursor: pointer;
}




/* counter */

.counter .desc {
  text-transform: uppercase;
  font-size: .8462rem;
  margin-top: 8px;
  color: #9d9d9d;
}


.form-group.row.pos {
  padding: 4px
}

.form-group.row.pos label {
  padding-top: 8px
}


.user-timeline-date{
  font-size: 1.2rem
}


ul.list-summary {
  list-style-type: none;
}

ul.summary-list li.summary-list-item {
  list-style-type: none;
  font-size: 1.5rem;
  border-bottom: 1px dashed gainsboro;  
  line-height: 30px
}

ul.summary-list li.summary-list-item p{
  line-height: 30px;
  margin : 0.75rem 0.25rem;
  font-weight: 300;
}

ul.summary-list li.summary-list-item .item-left {
   
}
ul.summary-list li.summary-list-item .item-right{
  float: right
}
 
.data-grid-container .data-grid {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.data-grid-container .data-grid .cell {
  height: 38px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  background-color: unset;
  transition: background-color 500ms ease;
  vertical-align: middle;
  text-align: right;
  border: 1px solid #DDD;
  padding: 0;
}

.data-grid-container .data-grid .cell > input {
  outline: none !important;
  border: 2px solid red;
  text-align: right;
  width: calc(100% - 6px);
  height: 38px;
  background: none;
  display: block;
}

.data-grid-container .data-grid .cell .value-viewer, .data-grid-container .data-grid .cell .data-editor {
  display: block;
  padding-left: 15px;
  font-size: 1.2em;
  text-align: left;
}

.table > tbody > tr > td.timecell  {
  /* padding: 0px;
  width : 8px;
  height: 8px;
   */
   color : gray
}

.table > tbody > tr > td.timecell > div.active {
   background-color: yellow;
}


div.timeslot{
  width : 16px;
  background-color: yellow;

}


div.timeslot.hh{
   
  border-left: 1px solid gainsboro
}


.grid-container {
  display: grid;
  grid-template-columns: repeat(12,1fr);  
  width: 960px;
  height: 100%;
  /* grid-gap: 1px;
  background-color: #2196F3; */
  /* padding: 10px; */
}

.grid-container.hh {
   
   position: absolute;
   width: 960px;
   height: 550px;
   color : black
  /* padding: 10px; */
}
.grid-container.hh > .hh {
  grid-column: 1 span ;
  border-left: 1px solid;
  padding-top: 8px;
}



.grid-container-hh > div:after {
  background-color: #f1f3f4;
  content: "";
  height: calc(100% - 37px);
  left: 0;
  position: absolute;
  top: 30px;
  width: 1px;
}
.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  /* padding: 20px 0; */
  /* font-size: 30px; */
}
.grid-container > div.active {
  background-color:#009688;
  text-align: left;
  color: white;
  /* height: 90%; */
  /* padding: 20px 0; */
  /* font-size: 30px; */
}
.item1 {
  grid-column: 3 / 4;
}

.item2 {
  grid-column:  6/ 8;
}



.block-screen-maincontent {
        /* background: bisque; */
        width: calc(100vw - 230px);
        position: fixed;
        left: 230px;
        top: 60px;
        height: 100%;
        z-index: 1;
}
