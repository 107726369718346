
.wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 460px;
  }
  .dd-wrapper {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    display: inline-block;
    width: 60px;
  }
  .dd-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    line-height: 20px;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    cursor: default;
    position: relative;
    background-color: #fff;
  }
  .dd-header span {
    margin-right: 20px;
  }
  .dd-header-title {
     margin-left: 8px;
    color : gray;
  }
  .angle-down {
    color: #000;
    margin-right: 20px;
  }
  .dd-list {
    z-index: 10;
    position: absolute;
    width: 120px;
    border: 1px solid #dfdfdf;
    /* border-top: none; */
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 5px -1px #e8e8e8;
            box-shadow: 0 2px 5px -1px #e8e8e8;
    font-weight: 700;
    padding: 15px 0;
    max-height: 215px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .dd-list-item {
    width: 100%;
    
    padding: 8px 10px;
     
    cursor: default;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dd-list-item.selected {
    color: #fff;
    background-color: #ffcc01;
  }
  .dd-list-item:hover {
    color: #fff;
    background-color: #ffcc01;
  }
  .dd-wrapper-single {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    width: 265px;
  }
  .dd-wrapper-single .dd-header {
    border: 1px solid #ccc;
  }
  .dd-wrapper-single .dd-header .dd-header-name {
    font-weight: 400;
  }
  .dd-wrapper-single .dd-list {
    border: 1px solid #ccc;
    border-top: none;
  }