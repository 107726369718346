.th-grid-container {
    display: grid;
    grid-template-columns: 150px 8fr;
  }
  
  .th-grid-container .data {
    margin-bottom: -1px;
    height: 60px;
     padding : 0px 20px;
  }
  
  .th-grid-container-2 {
    display: grid;
    grid-template-columns: repeat(30, 1fr);
  }
  
  .th-grid-container-2 > div.slot {
    border: 1px solid gray;
    width: 80px;
    height: 60px;
    margin-bottom: -1px;
    margin-right: -1px;
    text-align: center;
  
  }
  