.date-row-container {
  display: grid;
  /* grid-template-columns: 150px 8fr; */
  grid-template-columns: 150px  1fr;
  border-bottom-color: #f1f3f4;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: -1px;
   
  min-height: 48px !important;
}

.date {
  grid-column: 1 / 2;
  font-size: 20px;
}

.date-bottom {
  /* border-bottom-color: lightgray;
  border-bottom-style: solid;
  border-bottom-width: 1px; */
  padding-left: 16px;
}

/* 15 hours */
/* fraction by 10 min */
/* get 90 (10 min fraction) */

.grid-container2 {
  display: grid;
  grid-template-columns: repeat(90, 1fr);

  width: 960px;
  margin-bottom: -1px;

  border-bottom-color: #f1f3f4;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: -1px;
}

.time-header {
  display: grid;
  grid-template-columns: repeat(90, 1fr);
  /* grid-template-columns: repeat(90, 35px); */
  width: 960px;
  /* height: 600px; */

  position: absolute;
  left: 165px;
}

.time-header > div {
  /* width : 120px; */
  padding-top: 8px;
  padding-left: 8px;

  /* text-align: center; */
}

/* #d6d7d8   #F1F3F4 */
.time-box-left {
  border-left-color: #d6d7d8;
  border-left-style: solid;
  border-left-width: 1px;
}
.time-box-right {
  border-right-color: #d6d7d8;
  border-right-style: solid;
  border-right-width: 1px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  /* font-size: 15px; */
  text-align: left;
  padding-top: 5px;
  padding-left: 8px;

  margin: 2px;

  color: white;
  /* background-color: #2196f3; */
  /* width : 120px; */
  z-index: 0;
}

.grid-item > div.line1 {
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden !important;
}

.grid-item > div.line2 {
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden !important;
}

.event1 {
  grid-column: 1 / 5;
  grid-row: 1;
}
.event2 {
  grid-column: 7 / 11;
  grid-row: 1;
}
