 
.data-grid-container .data-grid {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.data-grid-container .data-grid .cell {
  height: 38px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  background-color: unset;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  vertical-align: middle;
  text-align: right;
  border: 1px solid #DDD;
  padding: 0;
}

.data-grid-container .data-grid .cell > input {
  outline: none !important;
  border: 2px solid red;
  text-align: right;
  width: calc(100% - 6px);
  height: 38px;
  background: none;
  display: block;
}

.data-grid-container .data-grid .cell .value-viewer, .data-grid-container .data-grid .cell .data-editor {
  display: block;
  padding-left: 15px;
  font-size: 1.2em;
  text-align: left;
}

.table > tbody > tr > td.timecell  {
  /* padding: 0px;
  width : 8px;
  height: 8px;
   */
   color : gray
}

.table > tbody > tr > td.timecell > div.active {
   background-color: yellow;
}


div.timeslot{
  width : 16px;
  background-color: yellow;

}


div.timeslot.hh{
   
  border-left: 1px solid gainsboro
}


.grid-container {
  display: grid;
  grid-template-columns: repeat(12,1fr);  
  width: 960px;
  height: 100%;
  /* grid-gap: 1px;
  background-color: #2196F3; */
  /* padding: 10px; */
}

.grid-container.hh {
   
   position: absolute;
   width: 960px;
   height: 550px;
   color : black
  /* padding: 10px; */
}
.grid-container.hh > .hh {
  grid-column: 1 span ;
  border-left: 1px solid;
  padding-top: 8px;
}



.grid-container-hh > div:after {
  background-color: #f1f3f4;
  content: "";
  height: calc(100% - 37px);
  left: 0;
  position: absolute;
  top: 30px;
  width: 1px;
}
.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  /* padding: 20px 0; */
  /* font-size: 30px; */
}
.grid-container > div.active {
  background-color:#009688;
  text-align: left;
  color: white;
  /* height: 90%; */
  /* padding: 20px 0; */
  /* font-size: 30px; */
}
.item1 {
  grid-column: 3 / 4;
}

.item2 {
  grid-column:  6/ 8;
}



.block-screen-maincontent {
        /* background: bisque; */
        width: calc(100vw - 230px);
        position: fixed;
        left: 230px;
        top: 60px;
        height: 100%;
        z-index: 1;
}